import React, { useState } from "react";
import {
  FindInPage,
  ContactSupport,
  GroupWork,
  Security,
  Check,
} from "@material-ui/icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import ModalVideo from "react-modal-video";
import { Grid, Button, makeStyles } from "@material-ui/core";
import PlayCircleOutlineOutlined from "@material-ui/icons/PlayCircleOutlineOutlined";
import "../styles/modal-video.min.css";
import SEO from "../components/seo";
import hero1 from "../images/how-works-1.png";
import StartTrialForm from "../components/StartTrialForm";
import bg1 from "../images/halftone-3@2x.png";
import bg2 from "../images/tracks-2-smaller@2x.png";
import { indigo } from "@material-ui/core/colors";
import TypedText from "../components/TypedText/TypedText";
import { Link } from "gatsby";

const useStyles = makeStyles((theme) => ({
  textButton: {
    color: indigo[800],
    userSelect: "none",
    textDecoration: "none",
    "&:hover": {
      color: indigo[900],
    },
  },
}));

export default () => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:900px)");
  const smMatch = useMediaQuery("(min-width:1100px)");
  const xsMatch = useMediaQuery("(min-width:800px)");
  const [state, setState] = useState({
    open: false,
    vid1IsOpen: false,
    vid2IsOpen: false,
  });

  return (
    <Box>
      <SEO
        title="Learn about LabLog"
        description="LabLog is the lab notebook software for research teams. Keep lab notes organized, quickly find what you need and stay compliant to healthcare regulations."
      />
      <Container maxWidth="lg" style={{ paddingTop: "150px" }}>
        <Grid container spacing={0}>
          <Grid item lg={5} md={5} sm={12}>
            <Box
              color="#3d3d3d"
              component="h1"
              textAlign="left"
              fontSize={matches ? "h3.fontSize" : "2rem"}
            >
              <strong>
                The Electronic Lab Notebook software that helps find more free
                time
              </strong>
            </Box>

            <StartTrialForm align="left" />
          </Grid>
          <Grid
            item
            lg={7}
            md={7}
            sm={12}
            style={{ marginTop: matches ? "125px" : 0 }}
          >
            <img
              alt="LabLog notes are available on your laptop and phone"
              src={hero1}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "20px",
                marginBottom: "10px",
                width: "100%",
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          style={{ marginTop: matches ? "150px" : 0 }}
        >
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            style={{
              backgroundImage: `url(${bg1}), url(${bg2})`,
              backgroundRepeat: "no-repeat, no-repeat",
              backgroundSize: "210px 187px, 210px 187px",
              backgroundPosition: "left bottom, right top",
              height: matches ? "300px" : "50px",
            }}
          >
            <div
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "70%",
              }}
            >
              <div
                className="video"
                style={{
                  position: "relative",
                  paddingBottom: "56.25%" /* 16:9 */,
                  paddingTop: 25,
                  height: 0,
                }}
              >
                <iframe
                  title="Video Guide #1"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  src="https://www.youtube.com/embed/C3I-9Mgagpc?modestbranding=1&autohide=1&showinfo=0&rel=0"
                  frameBorder="0"
                />
              </div>
            </div>
          </Grid>
          <Grid item lg={1} md={1} sm={12} />
          <Grid item lg={4} md={4} sm={12}>
            <Box
              color="#3d3d3d"
              component="h2"
              textAlign="left"
              fontSize="h5.fontSize"
            >
              See why LabLog is a better choice
            </Box>

            <Button
              variant="outlined"
              color="secondary"
              size="large"
              onClick={() => setState({ open: true })}
              endIcon={<PlayCircleOutlineOutlined />}
            >
              Watch Video
            </Button>
          </Grid>
        </Grid>
        <ModalVideo
          channel="youtube"
          isOpen={state.open}
          autoPlay={1}
          videoId="C3I-9Mgagpc"
          onClose={() => setState({ open: false })}
        />
        <div style={{ marginTop: matches ? 150 : 0 }}>
          <Box
            color="#3d3d3d"
            component="h2"
            textAlign="center"
            alignContent="center"
            alignSelf="center"
            fontSize="h4.fontSize"
          >
            LabLog brings all your research together
          </Box>
          <Box p={6} mt={4} pl={matches ? "150px" : "25px"}>
            <Box fontSize={smMatch ? "48px" : "35px"}>See for yourself why</Box>
            <Box fontSize={smMatch ? "48px" : "35px"}>
              researchers trust LabLog for
            </Box>
            <Box mt={1}>
              <TypedText
                variant={smMatch ? "h3" : "h5"}
                color="secondary"
                fontSize="48px"
                typedProps={{
                  strings: [
                    "Document Management.",
                    "Lab Inventory.",
                    "Team Management.",
                    "Audit Trails.",
                    "FDA Compliance.",
                    "Signatures.",
                    "File Management.",
                    "Version Control.",
                    "Custom Workflows.",
                  ],
                  typeSpeed: 50,
                  loop: true,
                }}
              />
            </Box>
          </Box>
          <div
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              width: matches ? "50%" : "100%",
            }}
          >
            <Box
              color="#3d3d3d"
              component="p"
              textAlign="center"
              alignContent="center"
              alignSelf="center"
              fontSize="h6.fontSize"
            >
              Your research in LabLog is organized into Methods and Tasks where
              you can store data, notes and files — helping everyone save time
              and collaborate together.
            </Box>
          </div>
        </div>

        <Grid container spacing={6} style={{ marginTop: "30px" }}>
          <Grid item sm={4} xs={12}>
            <p style={{ fontSize: "large", color: "#1976d2" }}>
              <FindInPage style={{ fontSize: "55px" }} />
              <br />
              <strong>Find notes right away</strong>
            </p>
            <p style={{ fontSize: "large" }}>
              Everything you add to your digital lab notebook is indexed —
              content inside files, conversations, data, and notes. Typos
              allowed!
            </p>
          </Grid>
          <Grid item sm={4} xs={12}>
            <p style={{ fontSize: "large", color: "#1976d2" }}>
              <GroupWork style={{ fontSize: "55px" }} />
              <br />
              <strong>Collaborate &amp; save time</strong>
            </p>
            <p style={{ fontSize: "large" }}>
              Share files, data and notes with your team. LabLog makes it easy
              to collaborate — no longer waiting for emails.
            </p>
          </Grid>
          <Grid item sm={4} xs={12}>
            <p style={{ fontSize: "large", color: "#1976d2" }}>
              <ContactSupport style={{ fontSize: "55px" }} />
              <br />
              <strong>Local support &amp; custom features</strong>
            </p>
            <p style={{ fontSize: "large" }}>
              From Boston to San Diego our experts regularly visit clients in
              person. Same level of professional service for all team sizes!
            </p>
          </Grid>
        </Grid>
        <Box
          color="#3d3d3d"
          component="h3"
          textAlign="left"
          alignContent="center"
          alignSelf="center"
          fontSize="p.fontSize"
        >
          <strong>Take notes and upload files</strong>
        </Box>

        <Grid container spacing={0} style={{ marginTop: "30px" }}>
          <Grid item lg={6} md={6} sm={12}>
            <div
              className="video"
              style={{
                position: "relative",
                paddingBottom: "56.25%" /* 16:9 */,
                paddingTop: 25,
                height: 0,
              }}
            >
              <iframe
                title="Video Guide #2"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                src="https://www.youtube.com/embed/UHJzmRirfwY?modestbranding=1&autohide=1&showinfo=0&rel=0"
                frameBorder="0"
              />
            </div>
          </Grid>
          <Grid item lg={1} md={1} sm={12} />
          <Grid item lg={4} md={4} sm={12}>
            <Box
              color="#3d3d3d"
              component="h2"
              textAlign="left"
              fontSize="h5.fontSize"
            >
              See how research is recorded in LabLog — increasing productivity
              &amp; reducing costs
            </Box>

            <Button
              variant="outlined"
              color="secondary"
              size="large"
              onClick={() => setState({ vid1IsOpen: true, vid2IsOpen: false })}
              endIcon={<PlayCircleOutlineOutlined />}
            >
              Watch Video
            </Button>
          </Grid>
        </Grid>
        <ModalVideo
          channel="youtube"
          isOpen={state.vid1IsOpen}
          autoPlay={1}
          videoId="UHJzmRirfwY"
          onClose={() => setState({ vid1IsOpen: false })}
        />
        <Box
          color="#3d3d3d"
          component="h3"
          textAlign="left"
          alignContent="center"
          alignSelf="center"
          fontSize="p.fontSize"
        >
          <strong>Collaborate &amp; share files</strong>
        </Box>

        <Grid container spacing={0} style={{ marginTop: "30px" }}>
          <Grid item lg={6} md={6} sm={12}>
            <div
              className="video"
              style={{
                position: "relative",
                paddingBottom: "56.25%" /* 16:9 */,
                paddingTop: 25,
                height: 0,
              }}
            >
              <iframe
                title="Video Guide #3"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                src="https://www.youtube.com/embed/Dyxk4GPG6Yw?modestbranding=1&autohide=1&showinfo=0&rel=0"
                frameBorder="0"
              />
            </div>
          </Grid>
          <Grid item lg={1} md={1} sm={12} />
          <Grid item lg={4} md={4} sm={12}>
            <Box
              color="#3d3d3d"
              component="h2"
              textAlign="left"
              fontSize="h5.fontSize"
            >
              See how shared notes brings labs together
            </Box>

            <Button
              variant="outlined"
              color="secondary"
              size="large"
              onClick={() => setState({ vid2IsOpen: true })}
              endIcon={<PlayCircleOutlineOutlined />}
            >
              Watch Video
            </Button>
          </Grid>
        </Grid>
        <ModalVideo
          channel="youtube"
          isOpen={state.vid2IsOpen}
          autoPlay={1}
          videoId="Dyxk4GPG6Yw"
          onClose={() => setState({ vid2IsOpen: false })}
        />
        <Grid container spacing={0} style={{ marginTop: "30px" }}>
          <Grid item lg={6} md={6} sm={12}>
            <br />
            <br />
            <Box
              color="#3d3d3d"
              component="p"
              textAlign="left"
              fontSize="h6.fontSize"
            >
              <strong>Security and protection</strong>
              <br />
              <Check
                baselineShift="0"
                style={{
                  verticalAlign: "bottom !important",
                  lineHeight: "12",
                }}
              />
              Independently assessed for compliance to various security
              standards, including SOC 2, ISO 27001, and FDA 21 CFR Part 11
            </Box>
            <Box
              color="#3d3d3d"
              component="p"
              textAlign="left"
              fontSize="h6.fontSize"
            >
              <Check
                baselineShift="0"
                style={{
                  verticalAlign: "bottom !important",
                  lineHeight: "12",
                }}
              />
              Single-sign-on via industry standard authentication protocols
            </Box>
            <Box
              color="#3d3d3d"
              component="p"
              textAlign="left"
              fontSize="h6.fontSize"
            >
              <Check
                baselineShift="0"
                style={{
                  verticalAlign: "bottom !important",
                  lineHeight: "12",
                }}
              />
              Encryption of data in transit and at rest
            </Box>
            <Box
              color="#3d3d3d"
              component="p"
              textAlign="left"
              fontSize="h6.fontSize"
            >
              <Check
                baselineShift="0"
                style={{
                  verticalAlign: "bottom !important",
                  lineHeight: "12",
                }}
              />
              Integrated with Microsoft security and compliance service
            </Box>
          </Grid>
          <Grid item lg={1} md={1} sm={12} />
          <Grid item lg={4} md={4} sm={12}>
            <Box
              color="#3d3d3d"
              component="p"
              textAlign="left"
              fontSize="h5.fontSize"
            >
              <Security style={{ fontSize: "55px" }} />
              <br />
              <strong>Keep your research data secure</strong>
            </Box>
            <Box
              color="#3d3d3d"
              component="p"
              textAlign="left"
              fontSize="h6.fontSize"
            >
              We use LabLog at LabLog, that&#39;s why your data is protected
              just like ours. We work with labs every day to make sure their
              research data security needs are met or exceeded.
            </Box>
          </Grid>
        </Grid>
      </Container>
      <div
        style={{
          marginTop: 150,
          backgroundColor: indigo[50],
          paddingTop: 70,
          paddingBottom: 70,
        }}
      >
        <Box
          component="p"
          textAlign="center"
          alignSelf="center"
          fontSize="h5.fontSize"
        >
          <Link to="/request-demo" className={classes.textButton}>
            <strong>Request a personalized demo</strong>
          </Link>
        </Box>
      </div>
    </Box>
  );
};
